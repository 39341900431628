.logo {
    /*height: 32px;*/
    /*background: rgba(255, 255, 255, 0.2);*/
    margin: 16px;
    font-size: 32px;
    text-align: center;
    color: white
}

.workspace {
    background: white;
    padding: 24px;
    min-height: 100%;
}

.menu-item {
    color: white
}

.menu-item:hover {
    color: whitesmoke
}

.avatar-button:hover {
    cursor: pointer;
}