@import "~antd/dist/antd.css";
/* UTILS */

.h-100 {
  height: 100%;
}

.t-50 {
  background: rgba(255, 255, 255, 0.6);
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

/* Login */

.background {
  background: #f5f6f8;
}

.floating-brand {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 175px;
}

.layout-login-card {
  border-radius: 25px;
  border-color: #40a9ff;
}

/* Form validation */

.red-alert > .ant-alert-message {
  color: red !important;
}

.red-alert-floating {
  border: 0;
  background: transparent;
}

/* Home */

.layout-card {
  background-color: white;
  border-radius: 25px;
}

.custom-header-card {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 25px 25px 0 0;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d0cece;
}

.input-error {
  border: 1px solid red;
}

.input-error-text > .ant-alert-message {
  color: red !important;
  font-size: 13px;
}

/* Objectives detail */

.objective-item {
  background: #ececec;
}

.objective-submenu {
  background: #e1e1e1;
}

/* Objective list */
.tag-in-list-button:hover,
.tag-in-list-button:focus {
  border-color: #722ed1;
  color: #722ed1;
}

.tag-in-list-button {
  color: #722ed1;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  border-color: #722ed1;
}

.save-tag-in-popover-button:hover,
.save-tag-in-popover-button:focus {
  border-color: #722ed1;
  color: #722ed1;
}

.save-tag-in-popover-button {
  color: #722ed1;
}

.tag-in-list-button-empty:hover,
.tag-in-list-button-empty:focus {
  border-color: #ccc;
  color: #ccc;
}

.tag-in-list-button-empty {
  border-color: #ccc;
  color: #ccc;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.date-in-list-button {
  color: #2f54eb;
  border-color: #2f54eb;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.date-in-list-button:hover,
.date-in-list-button:focus {
  border-color: #2f54eb;
  color: #2f54eb;
}

.date-in-list-button-empty {
  border-color: #ccc;
  color: #ccc;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.date-in-list-button-empty:hover,
.date-in-list-button-empty:focus {
  border-color: #ccc;
  color: #ccc;
}
/*#f94e4e*/

.user-in-list-button {
  color: #2f54eb;
  border-color: #2f54eb;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.user-in-list-button:hover,
.user-in-list-button:focus {
  border-color: #2f54eb;
  color: #2f54eb;
}

.user-in-list-button-not-assigned {
  color: #f94e4e;
  border-color: #f94e4e;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.user-in-list-button--not-assigned:hover,
.user-in-list-button--not-assigned:focus {
  border-color: #f94e4e;
  color: #f94e4e;
}

.user-in-list-button-empty {
  border-color: #ccc;
  color: #ccc;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.user-in-list-button-empty:hover,
.user-in-list-button-empty:focus {
  border-color: #ccc;
  color: #ccc;
}

.expand-button {
  scale: 0.7;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
}

.collapse-button {
  display: none;
  scale: 0.7;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
}

.close-input-in-row-button:hover,
.close-input-in-row-button:focus {
  border-color: red;
  color: red;
}

.close-input-in-row-button {
  color: red;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.save-input-in-row-button:hover,
.save-input-in-row-button:focus {
  border-color: #40a9ff;
  color: #40a9ff;
}

.save-input-in-row-button {
  color: #40a9ff;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.ant-cascader-menu {
  max-width: 500px;
}

.list-item {
  padding: 10px 0;
  /*background-color: #f0f2f5;*/
}

.objective-name:hover {
  color: #1890ff;
  cursor: pointer;
}

.objective-modal {
  width: 85% !important;
  max-width: 1536px;
}

.modal-create-objective {
  width: 55% !important;
  max-width: 1200px;
}

.close-input-in-form-button:hover,
.close-input-in-form-button:focus {
  border-color: red;
  color: red;
}

.close-input-in-form-button {
  color: red;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.save-input-in-form-button:hover,
.save-input-in-form-button:focus {
  border-color: #40a9ff;
  color: #40a9ff;
}

.save-input-in-form-button {
  color: #40a9ff;
  scale: 0.8;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.modal-objective-detail-form-item {
  margin-bottom: 0px;
}

/*********************** User modal ***************************/
.create-user-modal {
  width: 85% !important;
  max-width: 1536px;
}

.ant-progress-inner {
  background: #ccc;
}

/*********************** Calendar ***************************/

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}


/*********************** Calendar ***************************/
.list-item {
  border-radius: 8px;
  background-color: white;
}